@import "@/scss/_variables.scss";




























































































































































































































#sfx-user-navigation {
    .org-switch {
        background-color: rgba(0, 0, 0, 0.3);
        display: inline-block;
        margin-bottom: 0;
    }
}
