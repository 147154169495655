@import "@/scss/_variables.scss";


















































































.color-pickr.popup-style {
    position: absolute;
    background-color: $input-background-color;
    padding:25px;
    z-index:4;
    left: 20px;
    top: 0;
    .hu-color-picker {
        margin-right:25px;
    }
    .popup-close {
        position:absolute;
        top:15px;
        right:15px;
        z-index:4;
    }
}
