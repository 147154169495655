@import "@/scss/_variables.scss";



























































































































































.mat-color {
    width: 55px;
}
.mat-color-row {
    display: inline-flex;
    margin-bottom: 10px;
    width: 100%;
}

.material-select {
    height: 40px;
    width: 100%;
    margin: auto 0;
}
