@import "@/scss/_variables.scss";





















.info-helper {
    display:inline-block;
    padding-top:5px;
    position:relative;
    z-index:5;
    cursor:pointer;
    .tooltip {
        min-width: 370px;
        position:absolute;
        background-color: $info-helper-background-color;
        pointer-events:none;
        overflow:auto;
        padding:10px;
    }
    &:hover {
        .tooltip {
            opacity:1;
        }
    }
}
