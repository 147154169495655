@import "@/scss/_variables.scss";























































































































.pagination {
    ul {
        margin:auto;
        font-size:1.1em;
        display: flex;
        padding: 5px;
        li {
            list-style:none;
            cursor:pointer;
            min-width:2em;
            text-align: center;

            &:hover, &.active {
                color: $highlight;
            }
        }
    }
}
