@import "@/scss/_variables.scss";
















































































































































.title-edit {
    position:relative;
    word-break: break-all;
    .edit-trigger {
        position:absolute;
        margin-top:-3px;
        top:50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

}
.form-text {
    width:100%;
}
