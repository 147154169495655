@import "@/scss/_variables.scss";


















































.notification-container {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 300px;
    background-color: #000;
    padding:25px;
    z-index:65;
   /* animation:fadeOut  0.5s 1;
    -webkit-animation:fadeOut  0.5s 1;
    animation-fill-mode: forwards;*/

    /*animation-delay:10s;
    -webkit-animation-delay:10s;
    -webkit-animation-fill-mode: forwards;*/

}
.notification-close {
    color: #fff;
    position:absolute;
    top:15px;
    right:15px;
    cursor:pointer;
    padding:5px;
}
@keyframes fadeOut {
    from {opacity :1;}
    to {opacity :0;}
}
