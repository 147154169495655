@import "@/scss/_variables.scss";











































































































































































































































































































































































































































































































































































































































.center-vertical {
    margin-top: auto;
    margin-bottom: auto;
}
.custom-select {
    height: 40px;
}
label {
    display: block;
}
.cell {
    width: 400px;
    display: block;
}

.table-row .cell {
    display: flex;
}
.row {
    display: flex;
    margin: 10px 0 0 0;
    width: 100%;
}

.select {
    height: 40px;
    width: 100%;
    margin-bottom: 8px;
}

div.content {
    background-color: red !important;
}

.dirty {
    background-color: #ddd;
}

.col-5 {
    padding: 0px;
}

.sourceTitle {
    background-color: black;
    padding: 7px;
}
.error {
    max-width: 100%;
}
.table-header {
    height: 40px;
    background-color: #000;
}
.table-row {
    display: flex;
    background: #222;
    padding: 5px;
}
.flex-right {
    justify-content: right;
    display: flex;
}
