@import "@/scss/_variables.scss";





































































































.meta-panel {
    background-color: $meta-panel-background-color;
    margin-bottom:15px;
    margin-top:15px;
    h4 {
        padding:15px;
        padding-left:15px;
        margin-bottom:0;
    }
}
