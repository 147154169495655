@import "@/scss/_variables.scss";































.vform-header {
  &.emphasized {
    background-color: $highlight-color;
  }
}
