@import "@/scss/_variables.scss";






















































































































































































































































































.table-empty {
    width: 100%;
    padding:15px;
    background-color: $table-row-even-background;
}
table {
    table-layout: fixed;
    .sensor-icon {
        width: 20px;

        path {
            fill: #fff;

        }
    }
    .color-preview {
        position: absolute;
        z-index: 5;

        &.selected {
            z-index: 6;
        }
    }

    .table-row-title {
        font-size: 1.1em;
        display: block;
    }

    .tag {
        margin-top: 8px;
    }

    .selectLinking {
        cursor: pointer;
        width: 48px;
        min-width: auto;
        max-width:48px;
        .selected svg, svg:hover {
            color: $highlight;
        }
    }

    .table-row-instances {
        .icon {
            float:left;
        }
        .link-name {
            line-break: anywhere;
            max-width: 80%;
        }
    }

    .checkbox {
        width: 20px;
        height: 20px;
        margin: auto;
        border: 1px solid $brighter-border-color;
        background-color: #444;
        position: relative;
        cursor: pointer;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        &.selected, &:hover {
            border-color: $highlight;
            background-color: $highlight;
        }
    }

    a:hover {
        color: #fff;
    }

    td, th {
        padding: 15px;
        min-width: 80px;
        position: relative;
        word-break: break-all;
      &.editButton {
        width: 100px;
        padding-left:5px;
        padding-right:5px;
      }
      &.date {
        width: 120px;
      }
      &.type {
        width: 120px;
      }
      &.buttonField {
        width: 60px;
      }
      &.field-id-name {
        width: 200px;
      }
      &.icon {
        width: 6.5vw;
        max-width: 120px;
        min-width:auto;
        .preview {
            width: 100%;
        }
      }
      &.image {
        width: 120px;
      }
    }

    th {
      background-color: #1E2022;
    }
    th.sortable {
        cursor: pointer;
      &:hover {
        background-color: #34383e;
      }
        &.active {
          background-color: #34383e;
        }
    }

    tr {
        border: 1px solid $table-border-color;
        width: 100%;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;

        .button.show-button-on-hover {
            opacity: 0;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
        }

        &.even {
            background-color: $table-row-even-background;
            .image, > .icon {
                background-color: $preview-in-table-border-color-even;
            }
        }

        &.odd {
            background-color: $table-row-odd-background;
            .image, > .icon {
                background-color: $preview-in-table-border-color-odd;
            }
        }

        &.theader {
            background-color: $table-header-color;
            color: $textcolor-bright;
            margin-bottom: 12px;
            margin-top: 12px;
            border: 0px;
        }

        &.even, &.odd {
            &.no-access {
                background-color: $table-inactive-row-background;
            }

            &.hoverRow {
                cursor: pointer;

                &:hover {
                    background-color: $table-row-hover-background;
                }
            }
          &.hoverRowHighlight {
            cursor: pointer;

            &:hover {
              background-color: $highlight;
            }
          }

            &:hover {
                .button {
                    opacity: 1;
                }
            }
        }

        &.active {
            //background-color: $highlight;
            .rowSelector {
                color: $highlight;
            }
        }
        &.highlighted {
          background-color:  $highlight;
        }
    }

    .header {
        border: 1px solid transparent;
        border-bottom: 1px solid $table-border-color;
    }

    .rowSelector {
        font-size: 1.8em;
        cursor: pointer;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        color: #fff;

        &:hover {
            color: $highlight;
        }
    }
}
@media (min-width: 1680px) {
    table {
         td, th {
          &.field-id-name {
            width: 300px;
          }
          &.image {
            width: 150px;
          }
        }
    }
}
@media (min-width: 1920px) {
    table {
         td, th {
          &.field-id-name {
            width: auto;
          }
          &.image {
            width: 150px;
          }
        }
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait), screen and (max-width: 300px) {
    #vhub {
      background-color: red;
    }
}
@media (max-width: 768px) {
  .table-outer {
    max-width: 91vw;
    overflow: scroll;
    table {
      table-layout: auto;
      th, td {
        min-width: 130px;
      }
    }
  }
}

