@import "@/scss/_variables.scss";































































.view-switcher {
    display:inline-flex;
  height: $input-default-height;
}
.view-option {
    background-color: #2a2a2a;
    max-width:40px;
    padding:8px 12px;
    text-align: center;
    font-size:1em;
    border-right: 1px solid #000;
    cursor:pointer;
    &:last-child {
        border-right: 0px;
    }
    &:hover, &.active {
        background-color: $highlight;
    }
}
