@import "@/scss/_variables.scss";






















































.checkbox {
    width: 1.5rem;
    height: 1.5rem;
    background-color: lighten($highlight-color, 10%);
    float:left;
    margin-right: 8px;
    border: 1px solid transparent;
    border-radius: 50%;
    &.selected, &:hover {
      background-color: darken($highlight-color, 10%);
    }
    &.faulty {
      border: 1px solid red;
    }
}
