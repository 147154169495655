@import "@/scss/_variables.scss";






























.v-form-emphasized {
  font-size: 1.3em;
  color: $highlight-color;
  font-weight: bold;
}
