@import "@/scss/_variables.scss";









































































































































































































.form {
  width: 100vw;
  height: 90vh;
  background-color: #ddd;
}
.button-container {
  position: absolute;
  bottom: 0;
  padding-bottom:25px;
  width: 100%;
}
.button-container-upper {
  position: absolute;
  bottom: 40px;
  padding-bottom:25px;
  width: 100%;
}
.next-button, .prev-button {
  background-color: $highlight-color;
  max-width: 45%;
  cursor:pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: $on-dark-color;
  padding: 6px 10px;
  .icon {
    transition: all 300ms ease;
    margin-bottom: -3px;
  }
  .text {
    font-size: 1.1em;
  }
  &:hover {
    background-color: lighten($highlight-color, 10%);
  }
}
.next-button {
  padding-right: 35px;
  position:relative;
  .icon {
    position:absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  &:hover {
    .icon {
      right: 6px;
    }
  }
}
.prev-button {
  padding-left: 35px;
  position:relative;
  .icon {
    position:absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  &:hover {
    .icon {
      left: 6px;
    }
  }
}
.wrong-sign {
  position:absolute;
  background-color: #ff0008;
  left: 0;
  width: 0;
  height:100%;
  z-index:5;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  transition-timing-function: cubic-bezier(.17,.67,.83,.67);
  &.show {
    width: 2vw;
    left:-2vw;
  }
  &.correct {
    background-color: #518000;
  }
}
@keyframes slideIn {
  from {
    left: 0;
    width: 0;
  }
  to {
    width: 2vw;
    left:-3vw;
  }
}
.confetti {
  width: 10vh;
  height:10vh;
  position:absolute;
  bottom: 0;
  z-index:0;
  pointer-events:none;
}
