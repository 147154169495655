@import "@/scss/_variables.scss";





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.v-form-container {
    .info-panel .box {
        padding: 0px;
    }

    .info-panel .info {
        padding: 0px !important;
    }

    .form-error {
        width: 100%;
        max-width: 100%;
    }
}

.v-form-container {
    min-width: 1280px;
}

.fit-content {
    max-width: fit-content;
}

.vform-element .label {
    width: 120px;
}

.vform-element {
    background-color: #343434 !important;
}

.dflex {
    display: flex;
    flex-wrap: wrap;
}

.checkbox-wrapper {
    margin-bottom: 0.5rem;
}

.checkbox-wrapper label {
    margin: auto 2px;
    cursor: pointer;
}
.checkbox-wrapper input {
    margin: auto 0px;
    cursor: pointer;
}

.w-90 {
    max-width: 90%;
}
.w-50 {
    width: 50%;
}
.w-40 {
    width: 40%;
}
.w-10 {
    width: 10%;
}
.form-text-dark {
    background-color: #1f1f1f !important;
    color: white;
    border-color: #1f1f1f !important;
    border-radius: 2px;
}
.answer-delete {
    text-align: right;
    flex: auto;
}
