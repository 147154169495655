@import "@/scss/_variables.scss";























































































































.v-form-index {
  position:fixed;
  right:15px;
  bottom: 0;
  z-index:55;
  height: 175px;
  overflow-x: scroll;
  width: 80vw;
  background-color: #222;
  padding:15px;
  .slide {
    width: 150px;
    display:inline-block;
    margin-right: 5px;
    position:relative;
    .add-step-box {
      opacity: 0;
    }
    &:hover, &.active {
      .slide-img, .slide-img-faker {
        border: 3px solid $highlight;
      }
    }
    &:hover {
      .slide-mg-faker {
        background-color: $highlight;
      }
      .add-step-box {
        opacity: 1;
      }
    }
    &.no-steps, &.no-steps:hover {
      .slide-img, .slide-img-faker {
       border: 3px solid #222;
      }
      opacity: 0.3;
    }
    &.no-steps:hover {
      opacity: 1;
    }

  }
}
.v-form-inner {
  position:relative;
  left: 0;
}

.slide-img {
  background-color: #222;
  width: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 3px solid #222;
}
.slide-img-faker {
  background-color: #222;
  width: 100%;
  min-height:98px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.add-step-box {
  position:absolute;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 130px;
  background-color: $highlight;
  border-radius: .4em;
  padding:15px;
}

.add-step-box:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: $highlight;
  border-bottom: 0;
  margin-left: -10px;
  margin-bottom: -10px;
}
