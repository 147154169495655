@import "@/scss/_variables.scss";











































































































































































































































































































































.remove, .preview-download-button, .preview-download-button2 {
  opacity: 0;
}
.preview:hover {
  .remove, .preview-download-button, .preview-download-button2 {
    opacity: 1;
  }
}
.image-edit-toggler, .preview-download-button, .remove, .preview-download-button2 {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 1.3em;
  //padding: 6px 8px 6px 10px;
  width:2.3em;
  height:2.3em;
  background-color: $highlight;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  .icon {
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }

  &:hover {
    background-color: darken($highlight, 10%);
  }
}
.pdfpreview {
  .image-edit-toggler {
    top: 50px;
  }
  /*.preview-download-button, .remove {
    bottom: 50px;
  }*/
}
.preview-download-button {
  bottom: 15px;
  right: 15px;
  top:auto;
  background-color: $panel-background-color;
  p {
    font-size: 0.7rem;
    position:absolute;
    top: -15px;
    background-color: $panel-background-color;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity:0;
  }
  &:hover {
    p {
      opacity: 1;
    }
  }
}
.preview-download-button2 {
  bottom: 15px;
  left: 15px;
  top:auto;
  background-color: $panel-background-color;
  p {
    font-size: 0.7rem;
    position:absolute;
    top: -15px;
    background-color: $panel-background-color;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity:0;
  }
  &:hover {
    p {
      opacity: 1;
    }
  }
}
.remove {
  bottom:15px;
  left:15px;
  right:auto;
  top:auto;
  background-color: $panel-background-color;
}
.preview {
  position:relative;
  .selectLinking {
    cursor:pointer;
    position:absolute;
    top:0;
    right:0;
    z-index:3;
    div {
      padding:5px 8px;
      background-color: rgba(255,255,255,0.8);
      color:#000;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
      &.selected, &:hover {
        background-color: $highlight;
        color:#fff;
      }
    }
  }
}

