@import "@/scss/_variables.scss";










































.navpanel {
    z-index:1;
    .icon {
        font-size:1.3em;
        display:block;
        position:absolute;
        top:50%;
        left:50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        padding:0;
    }
    .nav-link {
        //padding: 15px;
        //background-color: #384858;
        background-color: $tab-item-background;
        display:block;
        padding:0;
        width: 40px;
        height:40px;
        position:relative;
        &:hover, &.router-link-active {
            color: #fff;
            background-color: $sub-panel-background-color;
        }
        &:hover {
            .title {
                opacity: 1;
            }
        }
    }
    .title {
        background-color: $tab-item-background-active;
        position:absolute;
        top:-29px;
        width:100px;
        opacity:0;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        padding:4px;
    }

}
