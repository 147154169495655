@import "@/scss/_variables.scss";



















































































































































































































































$right-section-width: 110px;
.export-profile-instance {
  background: #605E5B;
  padding: 15px;
  padding-top:0;
  padding-right: $right-section-width;
  position:relative;
  .info {
    word-wrap: break-word;
  }
}
.dark-section-right {
  background: #171615;
  height: 100%;
  position:absolute;
  right: 0;
  top:0;
  width: $right-section-width;
  text-align: center;
  padding:8px;
  .text {
    top: 50%;
    left: 50%;
    position:absolute;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 90%;
    text-align: center;
  }
}
.instance-delete-button {
}

.export-instance-download {
  cursor: pointer;
  &:hover {
    .icon {
     margin-bottom: -5px;
    }
  }
}
