@import "@/scss/_variables.scss";


























































































































































































































































.slide-locked,
.slide-locked img {
    opacity: 0.4 !important;
}
.slide-not-found {
    min-height: 67px;
    min-width: 100px;
    background: transparent;
    border: solid 1px $highlight !important;
    color: white;
    font-size: 14px;
}
.special-select-outer {
    position: relative;
    img.small {
        width: 100px;
    }
}
.special-select {
    width: 150px;
    height: 200px;
    overflow-y: scroll;
    position: absolute;
    img {
        max-width: 100%;
    }
}
.option {
    border: 3px solid transparent;
    &:hover img {
        opacity: 1;
    }
    &.active {
        border: 3px solid $highlight;
    }
    &.active img {
        opacity: 1;
    }
}

.slide-container img {
    opacity: 0.8;
}

.slide-img {
    width: 250px;
    height: auto;
    &:hover {
        opacity: 0.8;
    }
}

.highlight {
    border: dotted 2px grey;
    border-radius: 6px;
}

.slide-drop-zone {
    width: 25px;
    min-height: 50px;
}
.drop-active {
    background-color: $highlight !important;
}

.drag-el {
    cursor: move;
    border: solid 1px white;
    border-radius: 2px;
}
