@import "@/scss/_variables.scss";










































































































































































































































































































































































































































































































































































































.dropzone-outer {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.dz-progress {
    width: 100%;
    height:30px;
    pointer-events:none;
    background:$tab-item-background;
}
.dz-upload {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    background-color: $highlight;
    height:30px;
}
.dropzoneAndPreview {
    position: relative;
    width: 100%;
    padding-top: 100%;
    .preview {
        position: absolute;
        width: 100%;
        top: 0;
        z-index: 5;
        background-color: $tab-item-background;
        video {
            width: 100%;
        }
    }
    .form-error {
        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        word-break: break-all;
    }
}

.dropzone-square {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    padding-top: 100%;
}

.square-image .dropzonejs, .dropzone-square .dropzonejs {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 1px dashed #fff;
    cursor: pointer;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;

    &:hover, &.hover {
        background-color: darken($info-panel-color, 10%);
    }

    .fallback, .fallback input {
        width: 100%;
        position: absolute;
        top: 90%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .upload-text {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        width: 80%;
        pointer-events: none;
        word-wrap: break-word;
        .has-pointer {
          pointer-events:auto;
        }

        i {
            font-size: 2em;
            margin-top: 15px;
        }
    }
}

.dropzone-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.2);
}

.abort, .remove {
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 1em;
    //padding: 6px 8px 6px 10px;
    width: 2.3em;
    height: 2.3em;
    cursor: pointer;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    z-index: 5;
    opacity: 0.7;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    &:hover {
        opacity: 1;
    }
}
.remove {
    position:absolute;
    left: 30px;
    top: 30px;
}
.loading-overlay {
  width: 100%;
  height:100%;
  background-color: $tab-item-background;
  position:absolute;
  top:0;
  z-index:2;
  .half-circle-spinner, .text {
    position:absolute;
    top:55%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  .text {
    top: 40%;
    font-size: 1.2rem;
  }
}
