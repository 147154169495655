$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1500px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

/*$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
);*/


/*
---------
NEW
---------
*/
$logocolor: #fff;
$downloadPanelColor: #1c1e21;
$inputcolor: $downloadPanelColor;
$cornblue: #546cec;

/*
---------
Special Colors
---------
*/
$stagingColor: #a91346;
$demoColor: #929187;

/*
---------
Colors
---------
*/
$alert: #2069c3;
$background: #272C32;
$navbarcolor: #21252b;
$logocolorNotFront: #B1AEA7;
$highlight: #DA5E06;
$highlight-color: #455e26;
$on-dark-color: #fff;
$textcolor-bright: #fff;
$brightcolor: #fff;
$standard-border-color: #313030;
$brighter-border-color: #777474;



$input-background-color: #444;
$input-background-color-darker: #292b2d;

$nav-item-background: #7B7872;
$nav-item-text-color: #000;
$nav-item-background-active: #272C32;
$nav-item-text-color-active: #fff;
$tab-border-color: #7B7872;
$tab-item-background-active: #21252b;
$tab-item-background: #3B434D;
$tab-item-text-color-active: #fff;
$tab-item-text-color: #fff;

$info-panel-color: #7B7872;
$info-panel-header-color: #171615;
$tag-background-color: #7B7872;
$tag-background-dark: #2d2c29;

$filter-collapsible-background-color: lighten(#1d1d1f, 10%);
$filter-collapsible-content-background: lighten(#1d1d1f, 5%);
$filter-background-color: #1d1d1f;
$filter-label-background-color: #0f0f10;
$thumbnail-box-background-color: $filter-background-color;

$panel-background-color: #292827;
$panel-title-background-color: #7B7872;
$sub-panel-background-color: #181B1F;
$sub-sub-panel-background-color: #34383e;
$sub-sub-panel-border-color: #393e4a;
$sub-sub-panel-tab-border-color: #6d757d;
$sub-sub-panel-tab-color-active: #000;

$meta-panel-background-color: #21252b;
$meta-field-background-color: #7B7872;
$meta-field-editing-background-color: #21252b;
$ui-panel-background-color: #21252b;
/*
---------
Navs
---------
*/
$user-nav-height: 50px;

/*
---------
Tables
---------
*/
$table-header-color: #222427;
$table-header-darker-color: #171615;
$table-row-odd-background: #34383e;
$table-row-even-background: #1E2022;
$table-row-hover-background: #575d67;
$table-inactive-row-background: #252b31;
$table-border-color: $standard-border-color;


/*
---------
Previews
---------
*/
$preview-background-color: #131211;
$preview-border-color: lighten(#131211, 15%);
$preview-in-table-border-color-even: lighten($table-row-even-background, 2%);
$preview-in-table-border-color-odd: lighten($table-row-even-background, 2%);

/*
---------
Buttons
---------
*/
$default-button-font-color: #fff;
$default-button-height: 35px;
$default-button-background: #171615;
$button-hover-background: #2f343c;
$button-highlight-background: #c55303;
$deactivated-button-background: #7B7872;

/*
---------
TileButtons
---------
*/
$tile-button-background: #292b2d;
$tile-button-background-active: #67707b;
$tile-border-color: #4a515a;
$tile-active-border-color: #4a515a;

/*
---------
Sliders
---------
*/
$slider-handle-background-color: #7B7872;
$slider-tooltip-background-color: #292b2d;
$slider-border-color: darken(#7B7872, 20%);

/*
---------
Forms
---------
*/
$text-input-padding: 15px;
$input-default-height: 40px;

$pagination-background-color: $table-row-odd-background;

/*
---------
Info-Helper
---------
*/
$info-helper-background-color: #67707b;