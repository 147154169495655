@import "@/scss/_variables.scss";















































.checkbox {
    width: 1.5rem;
    height: 1.5rem;
    background-color: lighten($highlight-color, 10%);
    float:left;
    margin-right: 8px;
    border: 1px solid transparent;
    position:relative;
    .inner {
      width: 95%;
      height: 95%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
      &.selected, &:hover {
        .inner {
          background-color: darken($highlight-color, 10%);
        }
      }
      &.faulty {
        border: 1px solid red;
        .inner {
          border: 1px solid red;
        }
      }
}
