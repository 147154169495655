@import "@/scss/_variables.scss";



























































































































































































































.v-form-index {
    // position: fixed;
    // right: 15px;
    // bottom: 0;
    // z-index: 55;
    // height: 175px;
    // overflow-x: scroll;
    // width: 80vw;
    // background-color: #222;
    // padding: 15px;
    .slide {
        // width: 150px;
        // display: inline-block;
        // margin-right: 5px;
        // position: relative;
        .add-step-box {
            opacity: 0;
        }
        &:hover,
        &.active {
            .slide-img,
            .slide-img-faker {
                border: 3px solid $highlight;
            }
        }
        &:hover {
            .slide-mg-faker {
                background-color: $highlight;
            }
            .add-step-box {
                opacity: 1;
            }
        }
        &.no-steps,
        &.no-steps:hover {
            .slide-img,
            .slide-img-faker {
                border: 3px solid #222;
            }
            opacity: 0.3;
        }
        &.no-steps:hover {
            opacity: 1;
        }
    }
}

.slide-img-faker {
    background-color: #222;
    width: 100%;
    min-height: 98px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}
.add-step-box {
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 130px;
    background-color: $highlight;
    border-radius: 0.4em;
    padding: 15px;
}

.add-step-box:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: $highlight;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
}

/* Marius styles */
.v-form-index {
    display: flex;
    flex-wrap: wrap;
    .slide {
        width: 45%;
        position: relative;
        margin: 0.25rem;
    }

    .slide-img {
        width: 100%;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        border: 3px solid #343434;
    }
}

.active {
    border: solid 2px $highlight !important;
    border-radius: 3px;
    //background-color:#da5e06;
}

.slideNumber {
    position: absolute;
    bottom: 2px;
    left: 3px;
}

.drop-zone {
    min-height: 20px;
}
.highlight {
    border: dotted 2px grey;
    border-radius: 6px;
}

.drop-active {
    background-color: $highlight;
}

.drag-el {
    border: solid 2px #2d2d2d;
    border-radius: 3px;
    background: #2d2d2d;
}

.form-text-dark {
    background-color: #1f1f1f !important;
}

.drag-active {
    background: #999;
    border: solid 2px #999;
}

.drag-grip {
    cursor: move;
    color: grey;
}

.drag-grip:hover {
    color: white;
}

.step-warning {
    background: pink;
}
