@import "@/scss/_variables.scss";




















































#language-switch {
    background-color: rgba(0,0,0,0.3);
    display:inline-block;
    .custom-select {
        cursor:pointer;
        background-color: transparent;
        color: #fff;
        height: $user-nav-height;
        padding-left:8px;
        &:focus {
            box-shadow:none;
        }
    }
}
