@import "@/scss/_variables.scss";





































.loading-panel {
  &.bright {
    background-color: $tab-item-background;
  }
  &.dark {
    background-color: $tab-item-background-active;
  }
  &.black {
    background-color: #171615;
  }
}
.half-circle-spinner {
  margin:auto;
  margin-top:15px;
}
