@import "@/scss/_variables.scss";


























































































































































































.highlighted-row {
    border: 2px solid $highlight;
}

.tree {
    width: 100%;
    padding-right:15px;
    padding-top: 0;

    //todo: fix overflowscroll
    overflow: auto;
    overflow-y: scroll;
    max-height: 80vh;

    ul {
        width: 100%;
        list-style: none;
        padding: 0px 0px;
        margin: 0;
        color: #fff;
        //border-bottom: 1px solid $table-border-color;
        -webkit-transition: max-height 300ms;
        transition: max-height 300ms;
        background: lighten($panel-background-color, 10%);
        /*overflow: hidden;*/
        // "height: 0" not work with css transitions
        max-height: 0;

        svg {
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
        }


        &.open {
          max-height: 10000vh;
            .text.open .item-count {
                .icon {
                    -webkit-transform: translate(-50%, -50%) rotate(90deg);
                    transform: translate(-50%, -50%) rotate(90deg);
                    &:hover {
                      opacity: 1;
                    }
                }
            }

        }
        .hover-icons {
          display: none;
          position: absolute;
          right: 35px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          .icon {
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
          }
          .icon-container:hover .icon{
            opacity: 1;
          }
        }
        .text {
            padding: 12px 0px 12px 19px;
            width: 100%;
            //position: relative;
            cursor:pointer;
          position:sticky;
          top: 0;
          background: #7B7872;

            &.has-children {
                background-color: #171615;
                border-bottom: 1px solid $table-border-color;
                border-top: 1px solid $table-border-color;
            }

            &:hover, &.active {
                background-color: $highlight;
                .hover-icons {
                    display: flex;
                }
            }
            .linked-data {
                /*float:right;
                margin-right:35px;*/
              position: absolute;
              right: 85px;
              top: 50%;
              transform: translateY(-50%);
            }
            .item-count {
               // background:$tab-item-background;
                height:100%;
                padding:10px 15px;
                position: absolute;
                right: 0;
                top:0;
                cursor: pointer;
                -webkit-transition: all 300ms ease;
                transition: all 300ms ease;
                /*border-top: 1px solid $table-border-color;
                border-bottom: 1px solid $table-border-color;*/

                .icon {
                    position:absolute;
                    top:50%;
                    left:50%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    -webkit-transition: all 500ms ease;
                    transition: all 500ms ease;
                }
              &:hover .icon {
                 -webkit-transform: translate(-50%, -50%) rotate(90deg);
                    transform: translate(-50%, -50%) rotate(90deg);
              }
            }
        }

        &.level-2 {
            li {
              background: lighten($panel-background-color, 10%);
            }

            .text {
                padding-left: 3em;
            }
        }

        &.level-3 {
            .text {
                padding-left: 4.5em;
            }
        }
        &.level-4 {
            .text {
                padding-left: 6em;
            }
        }
        &.level-5 { .text {padding-left: 6.5em;} }
        &.level-6 { .text {padding-left: 7em;} }
        &.level-7 { .text {padding-left: 7.5em;} }
        &.level-8 { .text {padding-left: 8em;} }
        &.level-9 { .text {padding-left: 8.5em;} }
        &.level-10 { .text {padding-left: 9em;} }
        &.level-11 { .text {padding-left: 9.5em;} }
        &.level-12 { .text {padding-left: 10em;} }
    }

    li {

      div.text {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0px;
        border-bottom: 1px solid $table-border-color;
      }
      .node-name {
        max-width: 80%;
        padding-left:25px;
      }
      .icon-left {
        position:absolute;
        top:50%;
        transform: translateY(-50%);
      }
        .half-circle-spinner {
            display:inline-block;
        }
      .editing-icon {
        position:absolute;
        top:50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        &:nth-child(1) {
          right: 30px;
        }
        &:nth-child(2) {
          right:0px;
        }
      }
        .toggle-icon {
            height: 100%;
            background: red;
            position: absolute;
            right: 15px;
            padding: 5px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            cursor: pointer;
            transition: all 300ms ease;
            -webkit-transition: all 300ms ease;
            /*.icon {
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                top:50%;
                left:50%;
            }
            &.open .icon {
                -webkit-transform: translate(-50%, -50%) rotate(90deg);
                transform: translate(-50%, -50%) rotate(90deg);
            }*/
        }
    }
}
.overflowing {
  max-height: 80vh;
  overflow: auto;
  overflow-y: scroll;
}
.special-scrollbar {
  -ms-overflow-style: scrollbar;
  //&::-webkit-scrollbar { width: 0 !important }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #171615;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: $highlight;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: $highlight;
  }
}

