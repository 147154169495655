@import "@/scss/_variables.scss";
























.state-display {
  width: $input-default-height;
  height: $input-default-height;
  position:relative;
  .icon {
    position:absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  &.bright {
    background-color: rgba(255,255,255,0.5);
    color: #000;
  }
}
