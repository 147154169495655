@import "@/scss/_variables.scss";


































































































































































































































.ui-placeholder {
    position:relative;
    background-color: $ui-panel-background-color;
    padding-top: 50%;
    outline: 2px solid $navbarcolor;
}
