@import "@/scss/_variables.scss";























































































































































































.info-panel {
    background-color: $info-panel-color;
    margin-bottom:25px;

    .header {
        background-color: $info-panel-header-color;
      border-top: 1px solid #282d35;
      border-bottom: 1px solid black;
        position:relative;
        padding:25px;
        padding-top:15px;
        padding-bottom:15px;
        h1,h2,h3 {
            margin-bottom:0;
            padding-bottom:0;
        }
        .icon {
            position:absolute;
            right:15px;
            top:50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
        }
    }
    &.is-collapsed {
        .info, .box {
            height:0;
            padding-top:0;
            padding-bottom:0;
            overflow:hidden;
        }
        .header {
            .icon {
                -webkit-transform: translateY(-50%) rotate(-90deg);
                transform: translateY(-50%) rotate(-90deg);
            }
        }
    }
    .info {
        padding: 25px;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
    }
    .tag {
        background-color: $tag-background-dark;
        margin-top:4px;
    }
    .info-section {
        border-bottom: 1px solid darken($info-panel-color, 10%);
        padding-bottom:15px;
        margin-bottom: 15px;
        .label {
            display:inline-block;
            width: 49%;
        }
        .value {
            display:inline-block;
            width: 49%;
            text-align: right;
            //padding-bottom: 15px;
            //border-bottom: 1px solid darken($info-panel-color, 10%);
        }
    }
  &.compact-header {
    .header {
      padding-top: 8px;
      padding-bottom:8px;
    }
  }
  &.light-header {
    .header {
      background-color: darken($info-panel-color, 20%);
      border-color: transparent;
    }
  }
  &.smaller-title {
    .header {
      padding-left:8px;
      h1, h2, h3, h4 {
        font-size: 0.9rem;
      }
    }
  }
  &.darker-panel {
    background-color: darken($info-panel-color, 10%);
  }
  &.border-panel {
    border: 1px solid darken($info-panel-color, 10%);
  }
}
.image-edit-toggler {
    position:absolute;
    right:15px;
    bottom:15px;
    font-size: 1.3em;
    padding:4px 8px;
    background-color: $highlight;
    cursor:pointer;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    &:hover {
        background-color: darken($highlight, 10%);
    }

}
@media (max-width: 1500px) {
    .info-panel {
        .info {
            padding: 15px;
        }
        .info-section {
            .label {
                width: 100%;
                opacity:0.8;
            }
            .value {
                width: 100%;
                text-align: left;
            }
        }
    }
}
