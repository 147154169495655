@import "@/scss/_variables.scss";






























































































































































































































  // iPad landscape
@media only screen
    and (min-device-width: 1024px)
    and (max-device-width: 1024px)
    and (min-device-height: 768px)
    and (max-device-height: 768px)
    and (orientation: landscape)
    and (-webkit-min-device-pixel-ratio: 2) {
   .export-profile-right, .export-profile-left {
      flex: 0 0 100% !important;
      max-width: 100% !important;
  }
}
