@import "@/scss/_variables.scss";

































































































.thumbnail-empty {
    padding:15px;
    background-color: $table-row-even-background;
    width:100%;
}
.title-link {
    display:block;
    &:hover {
        color: $highlight;
    }
}
.thumbnail-view {
    h4 {
        line-height: 1.5em;
        font-weight: normal;
    }
    .date {
        font-size: 0.9em;
    }
    .icon-field {
        background-color: lighten($thumbnail-box-background-color, 5%);
    }
    .type {
        display: inline-block;
        font-size: 0.9em;
        padding: 4px 8px;
        background-color: darken($thumbnail-box-background-color, 5%);
    }
    .content {
        background-color: $thumbnail-box-background-color;
        padding-bottom: 25px;
        width: 100%;
        height: 100%;
        word-break: break-all;
        padding-bottom: 70px;
        position: relative;
        &.no-access {
            background-color: $table-inactive-row-background;
        }
    }

    .clickable .thumbnail {
        width: 100%;
        padding-top: 100%;
        margin-bottom: 15px;
        position: relative;
        overflow: hidden;

        .square-image, .three, .pdf {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;

            &:hover {
                transform: translate(-50%, -50%) scale(1.05);
                -webkit-transform: translate(-50%, -50%) scale(1.05);
            }
        }
    }

    .button {
        opacity: 0;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
    }

    .button-field {
        position: absolute;
        bottom: 15px;
        width: 80%;
    }
    .toggle-button {
        position:absolute;
        bottom: 0px;
        right:0px;
    }

    &:hover {
        .button {
            opacity: 1;
        }
    }
}
