@import "@/scss/_variables.scss";

















































.buttonField {
    position:absolute;
    right:15px;
    top: 50%;
    padding-top:15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width:100%;
    height: 90%;
    z-index:50;
    .opener {
        padding:15px;
        position:absolute;
        top:0;
        right:0;
        z-index: 5;
        &:hover {
            color: $highlight;
        }
    }
}
.buttonFieldContent {
    position:absolute;
    top:0;
    right:0;
    padding-right:25px;
    width:0;
    height:0;
    overflow:hidden;
    background-color: #494d54;
    z-index:0;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    opacity: 0;
    &.open {
        width:400px;
        height:100%;
        min-height: 50px;
        opacity: 1;
      &.large {
        min-height:500px;
      }
      &.medium {
        min-height: 300px;
      }
    }
    .buttonIcon {
        padding:15px;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        display:inline-block;
        &:hover {
            background: $nav-item-background-active;
        }
    }
}
.noPointerEvent {
    pointer-events: none;
}
