@import "@/scss/_variables.scss";


































.slide-control-element {
  background-color: $highlight-color;
  padding:8px;
  color: $on-dark-color;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  &:hover {
    background-color: lighten($highlight-color, 10%);
  }
}
