@import "@/scss/_variables.scss";

































































































































































































































































































.slideshow {
  width: 100%;
  height: 100vh;
 // background-color: #ddd;
 background-color: transparent;
  position: relative;
  overflow: hidden;
}
.slide-inner {
  width: 90%;
  position:absolute;
  left:0;
  height: 100%;
}
.slide {
  width: 100vw;
  height: 90vh;
  display: flex;
  position:absolute;
  left:0;
  top:0;
  z-index:-1;
  opacity: 0;
  pointer-events:none;
  .form {
    //width: 29%;
    //margin-left: 1%;
    position:relative;
  }
  &.active {
    opacity: 1;
    z-index:10;
  }
  img {
    width: 65%;
  }
}
.slide-header {
  width: 100%;
  background-color: #585b4e;
  z-index:15;
}
.version-error {
  width: 100%;
  background-color: #f87606;
  color: #fff;
  z-index:55;
  position:absolute;
  top:0;
  left:0;
}
