@import "@/scss/_variables.scss";
















































































































































































































.item-chooser {
    padding:15px;
    background: $panel-background-color;
    cursor:pointer;
    &:hover, &.selected {
        background: $highlight;
    }
}
