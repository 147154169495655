@import "@/scss/_variables.scss";







































































































.log-task {
  background-color: lighten($input-background-color, 10%);
  padding:3px 6px;
  margin-bottom:1px;
}
.log-task-not-done {
  background-color: $input-background-color;
}
.dz-progress {
  position:relative;
  width: 440px;
  padding:0;
  height:15px;
  margin-top:2px;
  margin-bottom: -2px;
  .dz-upload {
    background-color: $highlight;
    height:15px;
    p {
      position:absolute;
      left:5px;
      top:50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}
